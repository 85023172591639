import queryString from 'query-string';

import type { Category } from '~/db/schema';

import type {
  CategoriesMarketCapQuotationResponse,
  CategoriesReturnsResponse,
  CategoryMarketCapQuotationResponse,
  CategoryTreeResponse,
} from './type';

export const fetchCategories = async (): Promise<Category[]> => {
  const response = await fetch('/api/categories');
  return response.json();
};

export const fetchCreateCategory = async (data: {
  name: string;
  summary?: string;
  description?: string;
  parentId?: number;
}): Promise<void> => {
  const response = await fetch('/api/category/create', {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response.json();
};

export const fetchDeleteCategory = async (id: number) => {
  const response = await fetch(`/api/category/${id}/delete`, {
    method: 'POST',
  });
  return response.json();
};

export const fetchUpdateCategory = async ({
  id,
  name,
  summary,
  description,
  parentId,
}: {
  id: number;
  name: string;
  summary?: string;
  description?: string;
  parentId?: number;
}): Promise<void> => {
  const response = await fetch(`/api/category/${id}/update`, {
    method: 'POST',
    body: queryString.stringify({
      name,
      summary,
      description,
      parentId,
    }),
  });
  return response.json();
};

export const fetchCategoryTree = async (categoryId: number): Promise<CategoryTreeResponse> => {
  const response = await fetch(`/api/category/${categoryId}/tree`);
  return response.json();
};

export const fetchCategoriesMarketCapQuotation = async ({
  interval,
  categories,
}: {
  interval: '1day' | '1week';
  categories: number[];
}): Promise<CategoriesMarketCapQuotationResponse> => {
  const response = await fetch(
    `/api/categories/market-cap/quotation?${queryString.stringify({ interval, categories }, { arrayFormat: 'bracket' })}`,
  );
  return response.json();
};

export const fetchCategoryMarketCapQuotation = async ({
  categoryId,
  interval,
}: {
  categoryId: number;
  interval: '1day' | '1week';
}): Promise<CategoryMarketCapQuotationResponse> => {
  const response = await fetch(
    `/api/category/${categoryId}/market-cap/quotation?${queryString.stringify({
      interval,
    })}`,
  );
  return response.json();
};

export const fetchCategoriesReturns = async ({
  months,
  categories,
}: {
  months: number[];
  categories: number[];
}): Promise<CategoriesReturnsResponse> => {
  const response = await fetch(
    `/api/categories/returns?${queryString.stringify({ months, categories }, { arrayFormat: 'bracket' })}`,
  );
  return response.json();
};
